import { isEmpty } from "lodash";

export const getAnimationTargets = (co2data) => {
  if (isEmpty(co2data)) {
    return [{}, {}, false];
  }

  let active;
  let prevActive;

  for (let dp of co2data) {
    if (dp.active) {
      active = dp;
    }
    if (dp.prevActive) {
      prevActive = dp;
    }
  }

  const revert = !prevActive ? false : active.level < prevActive.level;

  return [active, prevActive, revert];
};

export const getActiveDataPoint = (co2data) => {
  if (isEmpty(co2data)) {
    return {};
  }

  for (let dp of co2data) {
    if (dp.active) {
      return dp;
    }
  }
};
