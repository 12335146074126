import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateFormOptions } from "../actions/formActions";
import { updateResultData } from "../actions/dataActions";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";

import DataFrame from "./DataFrame";
import FormWindow from "./FormWindow";

import { fetchCO2Data, fetchSelectionOptions } from "../middleware/API";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center" margin={2}>
      {"Copyright Valentin Starlinger © "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const useStyles = makeStyles((theme) => ({
  app: {
    padding: theme.spacing(2),
    height: "100%",
  },
}));

const App = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    const selectionOptions = fetchSelectionOptions();

    dispatch(updateFormOptions(selectionOptions));
  }, []);

  return (
    <Container className={classes.app}>
      <FormWindow />
      <DataFrame />
      <Copyright />
    </Container>
  );
};
export default App;
