import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getOptionById } from "../../selectors/formSelectors";
import { updateComboBoxSelection } from "../../actions/formActions";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    color: "black",
  },
}));

const ComboBoxSelection = ({ id }) => {
  const classes = useStyles();

  const formItem = useSelector((state) => {
    return getOptionById(state.formData.formTree, id);
  });

  const [selectedObjects, setSelectedObjects] = useState([
    ...formItem.selection.selectedItems,
  ]);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const dispatch = useDispatch();

  const options = formItem.options;

  const handleChange = (event) => {
    const newSelectedObjects = [...selectedObjects];
    if (event.target.checked) {
      newSelectedObjects.push(event.target.value);
      setSelectedObjects(newSelectedObjects);
    } else {
      const updatedSelection = newSelectedObjects.filter(
        (e) => e !== event.target.value
      );
      setSelectedObjects(updatedSelection);
    }
    console.log(selectedObjects);
  };

  const handleOK = () => {
    dispatch(updateComboBoxSelection(id, selectedObjects));
    setOpen(false);
  };

  const handleCancel = () => {
    setSelectedObjects(formItem.selection.selectedItems);
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={(e) => {
          return setOpen(true);
        }}
        variant="contained"
        color="primary"
      >
        {formItem.label}
      </Button>
      <Dialog disableBackdropClick disableEscapeKeyDown open={open}>
        <DialogTitle style={{ color: "black" }}>{formItem.label}</DialogTitle>
        <DialogContent>
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <FormGroup>
                {options.map((o) => {
                  if (searchText !== "" && !o.label.includes(searchText))
                    return false;
                  return (
                    <FormControlLabel
                      key={o.label}
                      value={o.label}
                      checked={selectedObjects.includes(o.label)}
                      control={<Checkbox color="primary" />}
                      label={o.label}
                      labelPlacement={"end"}
                      onChange={handleChange}
                      style={{ color: "black" }}
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOK} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ComboBoxSelection.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ComboBoxSelection;
