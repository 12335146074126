import { getSelectionById } from "../selectors/formSelectors";
import {
  DataActionType,
  FormActionType,
  PercentageActionType,
  SelectionType,
} from "../constants";
import { fetchCO2Data } from "../middleware/API";
import { interpolateRgb } from "d3-interpolate";
import { color } from "d3-color";

const changePercentageSelection = (formTree, oldSelection, action) => {
  const materials = oldSelection.materials;
  const percentages = oldSelection.percentages;

  const selection = getSelectionById(formTree, action.selectionId);
  if (action.changeType === PercentageActionType.PERCENTAGE_CHANGE) {
    selection.percentages[action.index] = Number(action.value);
    if (
      selection.percentages.reduce((a, b) => a + b, 0) >= 100 &&
      materials[materials.length - 1] === ""
    ) {
      selection.materials.pop();
    } else if (materials[materials.length - 1] !== "") {
      selection.materials.push("");
    }
  } else if (action.changeType === PercentageActionType.MATERIAL_CHANGE) {
    selection.materials[action.index] = action.value;
    if (
      action.index === materials.length - 1 &&
      selection.percentages.reduce((a, b) => a + b, 0) < 100
    ) {
      selection.materials.push("");
      selection.percentages.push(0);
    }
  }
};

const countFunction = (d) => {
  if (d.children) {
    var total = 0;
    for (var child of d.children) {
      total += countFunction(child);
    }
    return total;
  } else {
    return d.emissions;
  }
};

const processData = (
  data,
  converted_data,
  level,
  start_deg,
  stop_deg,
  deg_offset,
  parent = null
) => {
  const name = data.name;
  const total = countFunction(data);
  let current_deg = start_deg;
  if (start_deg === stop_deg) {
    return;
  }
  // create new data slice object with all the attributes of the current data index
  const data_slice = Object.assign({}, data);
  data_slice.children = undefined;
  //add additional attributes
  data_slice.start_deg = start_deg + deg_offset;
  data_slice.stop_deg = stop_deg + deg_offset;
  data_slice.level = level;
  data_slice.emissions = total;
  data_slice.parent = parent;

  if (data.color) {
    data_slice.color = data.color;
  } else {
    data_slice.color = color(
      interpolateRgb(
        parent.color,
        color("silver")
      )(1 - total / parent.emissions)
    );
  }

  converted_data.push(data_slice);

  if (data.children) {
    for (var child of data.children) {
      let inc_deg = ((stop_deg - start_deg) / total) * countFunction(child);
      let child_start_deg = current_deg;
      current_deg += inc_deg;
      let child_stop_deg = current_deg;
      processData(
        child,
        converted_data,
        level + 1,
        child_start_deg,
        child_stop_deg,
        deg_offset,
        data_slice
      );
    }
  }
  return converted_data;
};

const setNewActiveData = (data, newSelectedData) => {
  for (let obj of data) {
    if (obj.name === newSelectedData) {
      if (obj.active) {
        if (obj.parent !== null) {
          obj.parent.active = true;
          obj.prevActive = true;
        }
      }
      obj.active = true;
    } else if (obj.active) {
      obj.active = false;
      obj.prevActive = true;
    } else if (obj.prevActive) {
      obj.prevActive = false;
    }
  }
  return data;
};

const getMaxLevel = (co2data) => {
  let level = 0;
  for (let dp of co2data) {
    if (dp.level > level) {
      level = dp.level;
    }
  }
  return level;
};

export const manageResults = (state = {}, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case FormActionType.CHANGE_COMBOBOX_SELECTION:
      getSelectionById(
        newState.uncertainties,
        action.selectionId
      ).selectedItems = action.selectedItems;
      return newState;
    case FormActionType.CHANGE_SIMPLE_SELECT:
      getSelectionById(newState.uncertainties, action.selectionId).value =
        action.value;
      return newState;
    case FormActionType.CHANGE_PERCENTAGE_SELECTION:
      changePercentageSelection(
        newState.uncertainties,
        getSelectionById(state.uncertainties, action.selectionId),
        action
      );
      return newState;
    case DataActionType.UPDATE_DATA:
      newState.co2data = processData(
        action.newData,
        [],
        0,
        0,
        Math.PI,
        Math.PI
      );
      newState.co2data[0].active = true;
      newState.maxLevel = getMaxLevel(newState.co2data);
      return newState;

    case DataActionType.CLICK_DATA:
      setNewActiveData(newState.co2data, action.selectedDataPoint);
      return newState;

    case FormActionType.SUBMIT_FORM:
      const data = fetchCO2Data();
      newState.uncertainties = data.uncertainties;
      newState.co2data = processData(data.data, [], 0, 0, Math.PI, Math.PI);
      newState.co2data[0].active = true;
      newState.maxLevel = getMaxLevel(newState.co2data);
      return newState;
    case "SUBMIT_BOX":
      const submitted = newState.uncertainties.shift();
      if (submitted) {
          console.log("submitted")
        console.log(submitted)
        if (submitted.selectionType === SelectionType.PERCENTAGE_SELECTION) {
          console.log("p")
          console.log(submitted.selection.materials[0])
          for (let option of submitted.options) {
            if (option.label === submitted.selection.materials[0]) {
                console.log("matches")
              if (option.changes) {
                for (let change of option.changes) {
                  for (let co2 of newState.co2data) {
                    console.log("iterating co2data " + co2.name)
                    if (co2.name === change.name) {
                      co2.emissions = change.emissions;
                    }
                  }
                }
              }
            }
          }
        }
        if (submitted.selectionType === SelectionType.SIMPLE_SELECT) {
          for (let option of submitted.options) {
            if (option.label === submitted.selection.value) {
              if (option.changes) {
                for (let change of option.changes) {
                  for (let co2 of newState.co2data) {
                    if (co2.name === change.name) {
                      co2.emissions = change.emissions;
                    }
                  }
                }
              }
            }
          }
        }
      }
      console.log(newState)
      return newState;
    default:
      return state;
  }
};
