import React, { Component } from "react";

import { scaleOrdinal } from "d3-scale";
import { schemeSet3 } from "d3-scale-chromatic";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { isEmpty } from "lodash";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import grey from "@material-ui/core/colors/grey";

import HierarchicalChart from "./dataVis/HierarchicalChart";
import InformationWindow from "./dataVis/InformationWindow";

const useStyles = makeStyles((theme) => ({
  dataPaper: {
    background:
      "linear-gradient(to bottom right, white 80%, " +
      theme.palette.secondary.main +
      " )",
    margin: theme.spacing(1),
  },
  dataGrid: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
}));

const DataFrame = (props) => {
  const [data, maxLevel] = useSelector((state) => {
    return [state.resultData.co2data, state.resultData.maxLevel];
  });
  const classes = useStyles();

  return (
    <Paper square={true} className={classes.dataPaper}>
      {isEmpty(data) ? (
        false
      ) : (
        <Grid
          container
          margin={1}
          alignItems="center"
          direction="row"
          justify="space-evenly"
          className={classes.dataGrid}
        >
          <Grid item xs={6}>
            <HierarchicalChart
              data={data}
              width={300}
              height={600}
              maxLevel={maxLevel}
            />
          </Grid>
          <Grid item xs={6}>
            <InformationWindow />
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};
export default DataFrame;
