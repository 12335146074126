import { SelectionType } from "../constants";
import { color } from "d3-color";

export const co2_data = {
  uncertainties: [
    {
      label: "Shirt Composition",
      selectionType: SelectionType.PERCENTAGE_SELECTION,
      options: [
        {
          label: "Cotton",
          changes: [
            {
              name: "Seeds",
              emissions: 200,
            },
            {
              name: "Water Supply",
              emissions: 200,
            },
          ],
        },
        { label: "Polyamide" },
        { label: "Wool" },
        { label: "Weed" },
      ],
      selection: { materials: [""], percentages: [100] },
    },
    {
      label: "Shirt Size",
      selectionType: SelectionType.SIMPLE_SELECT,
      options: [
        {
          label: "XS",
          changes: [
            {
              name: "Cutting",
              emissions: 200,
            },
          ],
        },
        {
          label: "S",
          changes: [
            {
              name: "Cutting",
              emissions: 500,
            },
          ],
        },
        {
          label: "M",
          changes: [
            {
              name: "Cutting",
              emissions: 1000,
            },
          ],
        },
        {
          label: "L",
          changes: [
            {
              name: "Cutting",
              emissions: 1500,
            },
          ],
        },
        {
          label: "XL",
          changes: [
            {
              name: "Cutting",
              emissions: 2000,
            },
          ],
        },
      ],
      selection: { value: "" },
    },
  ],
  data: {
    name: "T-Shirt",
    details: "This T-Shirt is very nice!",
    color: color("silver"),
    children: [
      {
        name: "Raw Material",
        color: color("maroon"),
        details: "This a very interesting text about cotton farming in China",
        children: [
          {
            name: "Agriculture",
            details: "This is a text about agriculture in China",
            children: [
              { name: "Seeds", emissions: 400, details: "Seeding the earth." },
              {
                name: "Water Supply",
                emissions: 500,
                details: "Here is going to be some text.",
              },
            ],
          },
          { name: "Farming", emissions: 1000, details: "" },
        ],
      },
      {
        name: "Textile Production",
        details: "This a very interesting text about cotton farming in China",
        color: color("purple"),
        children: [
          {
            name: "Yarn Spinning",
            emissions: 100,
            details:
              "This a very interesting text about cotton farming in China",
          },
          {
            name: "Fabric Knitting",
            details:
              "This a very interesting text about cotton farming in China",
            children: [
              {
                name: "Preprocessing",
                emissions: 300,
                details:
                  "This a very interesting text about cotton farming in China",
              },
              {
                name: "Knitting",
                emissions: 400,
                details:
                  "This a very interesting text about cotton farming in China",
              },
            ],
          },
          {
            name: "Fabric dying",
            emissions: 200,
            details:
              "This a very interesting text about cotton farming in China",
          },
        ],
      },
      {
        name: "Clothing Production",
        details: "This a very interesting text about cotton farming in China",
        color: color("green"),
        children: [
          {
            name: "Spreading",
            emissions: 800,
            details:
              "This a very interesting text about cotton farming in China",
          },
          {
            name: "Cutting",
            emissions: 1000,
            details:
              "This a very interesting text about cotton farming in China",
          },
          {
            name: "Printing",
            emissions: 1000,
            details:
              "This a very interesting text about cotton farming in China",
          },
          {
            name: "Sewing",
            emissions: 200,
            details:
              "This a very interesting text about cotton farming in China",
          },
        ],
      },
      {
        name: "Packaging",
        emissions: 200,
        color: color("orange"),
        details: "This a very interesting text about cotton farming in China",
      },
      {
        name: "Transportation",
        emissions: 200,
        color: color("burlywood"),
        details: "This a very interesting text about cotton farming in China",
      },
    ],
  },
};

export const fetchCO2Data = () => {
  return co2_data;
};

export const selectionOptions = [
  {
    id: "Product",
    label: "Product",
    selectionType: SelectionType.CATEGORY,
    options: [
      {
        id: "clothingCategory",
        label: "Clothing",
        selectionType: SelectionType.CATEGORY,
        options: [
          {
            id: "oberkleidung",
            label: "Outdoor Clothes",
            selectionType: SelectionType.CATEGORY,
            options: [
              {
                id: "tshirt",
                label: "T-Shirt",
                selectionType: SelectionType.CATEGORY,
              },
              {
                id: "skirt",
                label: "Skirt",
                selectionType: SelectionType.CATEGORY,
              },
            ],
          },
          {
            id: "unterkleidung",
            label: "Underwear",
            selectionType: SelectionType.CATEGORY,
            options: [
              {
                id: "socks",
                label: "Socks",
                selectionType: SelectionType.CATEGORY,
              },
            ],
          },
        ],
      },
      {
        id: "groceries",
        label: "Groceries",
        selectionType: SelectionType.CATEGORY,
        options: [
          {
            id: "groceries_fruit_veg",
            label: "Fruits/Vegetables",
            selectionType: SelectionType.CATEGORY,
          },
          {
            id: "groceries_dairy",
            label: "Dairy",
            selectionType: SelectionType.CATEGORY,
          },
        ],
      },
    ],
  },
];

export const fetchSelectionOptions = () => {
  return selectionOptions;
};
