import React, { useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
  getSelectedTree,
  getFormTree,
  getIsFinished,
} from "../../selectors/formSelectors";
import { submitBox } from "../../actions/dataActions";
import { SelectionType } from "../../constants";

import { isEmpty } from "lodash";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import PercentageSelection from "./PercentageSelection";
import ButtonGroupSelection from "./ButtonGroupSelection";
import SimpleSelect from "./SimpleSelect";
import ComboBoxSelection from "./ComboBoxSelection";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(1),
  },
  children: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  buttons: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "right",
    alignItems: "right",
  },
}));

const SubmitBox = ({ children, onSubmit, onDontKnow }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <div className={classes.children}>
        {children}
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color={"primary"}
            onClick={() => {
              dispatch(submitBox());
            }}
            fullWidth={false}
          >
            Submit
          </Button>
          <Button
            onClick={() => {
              dispatch(submitBox());
            }}
            fullWidth={false}
            size={"small"}
          >
            I don't know
          </Button>
        </div>
      </div>
    </Card>
  );
};
export default SubmitBox;
