import { isEmpty, isEqual } from "lodash";
import { DefaultSelections, SelectionType } from "../constants";

const searchOptionsById = (formTree, id) => {
  for (let option of formTree) {
    if (option.label === id || option.id === id) {
      return option;
    } else if (option.options) {
      const selection = searchOptionsById(option.options, id);
      if (selection) return selection;
    }
  }
};

export const getOptionById = (formTree, id) => {
  if (isEmpty(formTree)) return {};
  return searchOptionsById(formTree, id);
};

const searchSelectionId = (formTree, id) => {
  for (let option of formTree) {
    if (option.label === id || option.id === id) {
      return option.selection;
    } else if (option.options) {
      const selection = searchSelectionId(option.options, id);
      if (selection) return selection;
    }
  }
};

export const getSelectionById = (formTree, id) => {
  if (isEmpty(formTree)) return {};
  return searchSelectionId(formTree, id);
};

const createSelectedTree = (formData, selectedTree) => {
  for (let option of formData) {
    if (option.selection) {
      if (
        option.selection.selectedId !== undefined &&
        option.selection.selectedId !== null
      ) {
        selectedTree.push(option.selection.selectedId);
        if (option.options) {
          createSelectedTree(option.options, selectedTree);
        }
      }
    }
  }
  return selectedTree;
};

export const getSelectedTree = (state) => {
  if (!state.formData.formTree || isEmpty(state.formData.formTree)) return [];
  return createSelectedTree(state.formData.formTree, []);
};

export const getFormTree = (state) => {
  return state.formData.formTree;
};

const areChildrenFinished = (formTree) => {
  let allRequiredFinished = true;
  for (let option of formTree) {
    if (
      option.required &&
      isEqual(option.selection, DefaultSelections[option.selectionType])
    ) {
      console.log(
        "not finished yet because " +
          option.id +
          " is not finished but required"
      );
      console.log(option.selection);
      console.log(DefaultSelections[option.selectionType]);
      return false;
    } else if (
      option.required &&
      option.selectionType === SelectionType.CATEGORY
    ) {
      allRequiredFinished = areChildrenFinished([
        getOptionById(option.options, option.selection.selectedId),
      ]);
    } else if (option.selectionType === SelectionType.GROUP) {
      allRequiredFinished = areChildrenFinished(option.options);
    }
  }
  return allRequiredFinished;
};

export const getIsFinished = (state) => {
  console.log(state);
  if (!state.formData.formTree || isEmpty(state.formData.formTree))
    return false;
  return areChildrenFinished(state.formData.formTree);
};

export const getIsEditForm = (state) => {
  return !state.formData.hasSubmitted;
};
