import React, { useState } from "react";
import PropTypes from "prop-types";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { updateCategorySelection, submitForm } from "../../actions/formActions";

import { getOptionById } from "../../selectors/formSelectors";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

const ButtonGroupSelection = ({ id }) => {
  const formOption = useSelector((state) => {
    return getOptionById(state.formData.formTree, id);
  });
  const dispatch = useDispatch();

  return (
    <ButtonGroup>
      {" "}
      {formOption.options.map((o) => (
        <Button
          onClick={(e) => {
            dispatch(updateCategorySelection(id, o.id))
            if (!o.options){
              dispatch(submitForm(o.id))
            }
          }}
          variant="contained"
          color={
            formOption.selection.selectedId === o.id ? "secondary" : "primary"
          }
          key={o.id}
        >
          {o.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};

ButtonGroupSelection.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ButtonGroupSelection;
