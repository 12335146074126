import { combineReducers } from "redux";
import { manageFormData } from "./formReducer";
import { manageResults } from "./dataReducer";

const co2App = combineReducers({
  resultData: manageResults,
  formData: manageFormData,
});

export default co2App;
