import makeActionCreator from "./actionCreators";
import { DataActionType } from "../constants";

export const updateResultData = makeActionCreator(
  DataActionType.UPDATE_DATA,
  "newData"
);
export const setActiveDataPoint = makeActionCreator(
  DataActionType.CLICK_DATA,
  "selectedDataPoint"
);
export const submitBox = makeActionCreator("SUBMIT_BOX", "changes");
