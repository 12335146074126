import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getAnimationTargets } from "../../selectors/dataSelectors";
import { setActiveDataPoint } from "../../actions/dataActions";

import { select, selectAll } from "d3-selection";
import { interpolate } from "d3-interpolate";
import { arc } from "d3-shape";
import { transition } from "d3-transition";

const getStopAngle = (d, ref) => {
  if (ref) {
    var ref_span = ref.stop_deg - ref.start_deg;
    var r = ((d.stop_deg - ref.start_deg) / ref_span) * Math.PI;
    return r + Math.PI;
  } else {
    return d.start_deg;
  }
};

const getLevel = (d, ref) => {
  if (ref) {
    return d.level - ref.level;
  } else {
    return d.level;
  }
};

const getStartAngle = (d, ref) => {
  if (ref) {
    var ref_span = ref.stop_deg - ref.start_deg;
    var r = ((d.start_deg - ref.start_deg) / ref_span) * Math.PI;
    return r + Math.PI;
  } else {
    return d.start_deg;
  }
};

const HierarchicalChart = ({ data, height, width, maxLevel }) => {
  const [animating, setAnimating] = useState(false);

  const node = useRef(null);
  const dataRef = useRef({});
  const dispatch = useDispatch();

  const thickness = (height / 2.0 / (maxLevel + 2)) * 1.1;

  const data_arc = arc()
    .startAngle((d) => {
      if (d.level === 0) {
        return d.start_deg;
      }
      return d.start_deg + 0.01;
    })
    .endAngle((d) => {
      if (d.level === 0) {
        return d.stop_deg;
      }
      return d.stop_deg - 0.01;
    })
    .innerRadius((d) => {
      return 1.1 * d.level * thickness;
    })
    .outerRadius((d) => {
      return (1.1 * d.level + 1) * thickness;
    });

  useEffect(() => {
    select(node.current)
      .append("g")
      .attr("transform", "translate(" + width + "," + height * 0.52 + ")");
  }, []);

  useEffect(() => {
    //console.log("using effect");
    if (!data) {
      return;
    }
    //console.log("drawing data")
    dataRef.current = data[0];

    //console.log(node.current)
    var slices = select(node.current)
      .select("g")
      .selectAll(".form")
      .data((d) => {
        return data;
      })
      .enter()
      .append("g")
      .append("path")
      .attr("id", (d) => {
        return d.name;
      })
      .attr("d", data_arc)
      .style("fill", (d) => {
        return d.color;
      })
      .attr("class", "form");

    slices.on("click", (clickedPoint) => {
      if (!animating) {
        let isReverting = false;
        if (clickedPoint.name === dataRef.current.name) {
          if (clickedPoint.parent === null) {
            return;
          }
          isReverting = true;
          dispatch(setActiveDataPoint(clickedPoint.parent.name));
        } else {
          dispatch(setActiveDataPoint(clickedPoint.name));
        }
        //console.log("clicked: "+clickedPoint.name);
        animate(clickedPoint, isReverting);
      }
    });

    slices.append("svg:title").text((d) => {
      return d.name + " with " + d.emissions + " g CO2";
    });
  }, [data]);

  const rebaseTween = (toData) => {
    return (d) => {
      if (!d.ref) {
        d.ref = data[0];
      }
      //console.log("tweening " + d.name + " from: "+d.ref.name+" to "+toData.name)
      var level = interpolate(getLevel(d, d.ref), getLevel(d, toData));
      var start_deg = interpolate(
        getStartAngle(d, d.ref),
        getStartAngle(d, toData)
      );
      var stop_deg = interpolate(
        getStopAngle(d, d.ref),
        getStopAngle(d, toData)
      );
      d.ref = toData;
      return (t) => {
        return data_arc({
          start_deg: start_deg(t),
          stop_deg: stop_deg(t),
          name: d.name,
          level: level(t),
        });
      };
    };
  };

  const animate = (clickedData, isReverting) => {
    if (animating) {
      return;
    }
    setAnimating(true);

    let ref = dataRef.current;
    let animateFrom = dataRef.current;
    let animateTo = clickedData;

    //console.log("animating from "+animateFrom.name+" to "+animateTo.name)

    if (isReverting) {
      if (clickedData.parent === null) {
        //console.log("canceling animation, parent is null");
        return;
      } else {
        animateTo = clickedData.parent;
        ref = animateTo.parent == null ? animateTo : animateTo.parent;
        animateFrom = clickedData;
        //console.log("animating from "+animateFrom.name+" to "+animateTo.name)
      }
      //console.log("reverting");
      select(node.current)
        .select("g")
        .selectAll(".form")
        .filter((b) => {
          if (
            getStartAngle(b, ref) >= getStartAngle(animateTo, ref) &&
            getStopAngle(b, ref) <= getStopAngle(animateTo, ref) &&
            getLevel(b, ref) >= getLevel(animateTo, ref)
          ) {
            //console.log("setting opacity 1 for: "+b.name)
            return true;
          }
          return false;
        })
        .transition()
        .duration(1)
        .style("opacity", "1")
        .attr("pointer-events", "all");
    } else {
      select(node.current)
        .select("g")
        .selectAll(".form")
        .filter((b) => {
          if (
            b.start_deg < animateTo.start_deg ||
            b.stop_deg > animateTo.stop_deg ||
            b.level < animateTo.level
          ) {
            return true;
          }
          return false;
        })
        .transition()
        .duration(1000)
        .style("opacity", "0")
        .attr("pointer-events", "none");
    }
    select(node.current)
      .select("g")
      .selectAll(".form")
      .filter((b) => {
        if (
          getStartAngle(b, ref) >= getStartAngle(animateTo, ref) &&
          getStopAngle(b, ref) <= getStopAngle(animateTo, ref) &&
          getLevel(b, ref) >= getLevel(animateTo, ref)
        ) {
          //console.log("filtering: "+b.name)
          return true;
        }
        return false;
      })
      .transition()
      .duration(1000)
      .attrTween("d", rebaseTween(animateTo));
    setTimeout(() => {
      //console.log("stopped animating")
      setAnimating(false);
      dataRef.current = animateTo;
    }, 1000);
  };
  return <svg ref={node} width={width} height={height} />;
};
export default HierarchicalChart;
