import React from "react";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { editForm } from "../../actions/formActions";

import { getIsEditForm } from "../../selectors/formSelectors";

import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import grey from "@material-ui/core/colors/grey";
import EditIcon from "@material-ui/icons/Edit";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: theme.spacing(1),
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
}));

const FormToolBar = (props) => {
  const isEditing = useSelector(getIsEditForm);
  const dispatch = useDispatch();

  const classes = useStyles();

  return (
    <div>
      <Toolbar
        className={classes.toolbar}
        onClick={() => {
          dispatch(editForm());
        }}
      >
        {isEditing ? (
          <h3>Please choose what you want to analyze</h3>
        ) : (
          <>
            {" "}
            <IconButton edge="end" color="inherit">
              <EditIcon />
            </IconButton>
            <h3>Edit the selected category</h3>
          </>
        )}
      </Toolbar>
    </div>
  );
};
export default FormToolBar;
