import { merge } from "lodash";

import { getSelectionById } from "../selectors/formSelectors";
import {
  SelectionType,
  FormActionType,
  PercentageActionType,
  DefaultSelections,
} from "../constants";

const fillFormData = (formData) => {
  for (let option of formData) {
    let selection;
    if (DefaultSelections[option.selectionType]) {
      option.selection = merge({}, DefaultSelections[option.selectionType]); //cloneDeep
    }
    if (option.options) {
      fillFormData(option.options);
    }
  }
  return formData;
};

const setCategorySelection = (
  formData,
  selectionId,
  selectedId,
  foundResetPoint = false
) => {
  for (var option of formData) {
    if (option.selectionType === SelectionType.CATEGORY) {
      if (foundResetPoint) {
        if (
          option.selection.selectedId !== undefined ||
          option.selection.selectedId !== null
        ) {
          option.selection.selectedId = null;
        }
      } else {
        if (
          option.id === selectionId &&
          (option.selection.selectedId === null ||
            option.selection.selectedId !== selectedId)
        ) {
          option.selection.selectedId = selectedId;
          foundResetPoint = true;
        }
      }
    }
    if (option.options) {
      setCategorySelection(
        option.options,
        selectionId,
        selectedId,
        foundResetPoint
      );
    }
  }
  return formData;
};

const changePercentageSelection = (formTree, oldSelection, action) => {
  const materials = oldSelection.materials;
  const percentages = oldSelection.percentages;

  const selection = getSelectionById(formTree, action.selectionId);
  if (action.changeType === PercentageActionType.PERCENTAGE_CHANGE) {
    selection.percentages[action.index] = Number(action.value);
    if (
      selection.percentages.reduce((a, b) => a + b, 0) >= 100 &&
      materials[materials.length - 1] === ""
    ) {
      selection.materials.pop();
    } else if (materials[materials.length - 1] !== "") {
      selection.materials.push("");
    }
  } else if (action.changeType === PercentageActionType.MATERIAL_CHANGE) {
    selection.materials[action.index] = action.value;
    if (
      action.index === materials.length - 1 &&
      selection.percentages.reduce((a, b) => a + b, 0) < 100
    ) {
      selection.materials.push("");
      selection.percentages.push(0);
    }
  }
};

export const manageFormData = (state = {}, action) => {
  let newState = merge({}, state);
  switch (action.type) {
    case FormActionType.UPDATE_FORM_OPTIONS:
      newState.formTree = fillFormData(action.selectionOptions);
      break;
    case FormActionType.CHANGE_CATEGORY_SELECTION:
      setCategorySelection(
        newState.formTree,
        action.selectionId,
        action.selectedId
      );
      break;
    case FormActionType.SUBMIT_FORM:
      newState.hasSubmitted = true;
      break;
    case FormActionType.EDIT_FORM:
      newState.hasSubmitted = false;
      break;
    default:
      return state;
  }
  return newState;
};
