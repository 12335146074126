export const FormActionType = {
  CHANGE_CATEGORY_SELECTION: "CHANGE_CATEGORY_SELECTION",
  UPDATE_FORM_OPTIONS: "UPDATE_FORM_OPTIONS",
  CHANGE_PERCENTAGE_SELECTION: "CHANGE_PERCENTAGE_SELECTION",
  CHANGE_SIMPLE_SELECT: "CHANGE_SIMPLE_SELECT",
  CHANGE_COMBOBOX_SELECTION: "CHANGE_COMBOBOX_SELECTION",
  SUBMIT_FORM: "SUBMIT_FORM",
  EDIT_FORM: "EDIT_FORM",
};

export const PercentageActionType = {
  PERCENTAGE_CHANGE: "PERCENTAGE_CHANGE",
  MATERIAL_CHANGE: "MATERIAL_CHANGE",
};

export const SelectionType = {
  CATEGORY: "category",
  GROUP: "group",
  PERCENTAGE_SELECTION: "percentage_selection",
  COMBOBOX_SELECTION: "combobox_selection",
  SIMPLE_SELECT: "simple_select",
};

export const DataActionType = {
  CLICK_DATA: "CLICK_DATA",
  UPDATE_DATA: "UPDATE_DATA",
};

export const DefaultSelections = {
  [SelectionType.PERCENTAGE_SELECTION]: { materials: [""], percentages: [0] },
  [SelectionType.CATEGORY]: { selectedId: null },
  [SelectionType.COMBOBOX_SELECTION]: { selectedItems: [] },
  [SelectionType.SIMPLE_SELECT]: { value: "" },
};
