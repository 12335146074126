import React, { useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
  getSelectedTree,
  getFormTree,
  getIsFinished,
} from "../../selectors/formSelectors";
import { submitForm } from "../../actions/formActions";
import { SelectionType } from "../../constants";

import { isEmpty } from "lodash";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import PercentageSelection from "./PercentageSelection";
import ButtonGroupSelection from "./ButtonGroupSelection";
import SimpleSelect from "./SimpleSelect";
import ComboBoxSelection from "./ComboBoxSelection";

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(1),
    "& div": {
      margin: theme.spacing(1),
    },
  },
  buttongroups: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  submit: {
    display: "flex",
    justifyContent: "right",
    alignItems: "right",
  },
}));

const MainForm = (props) => {
  const formTree = useSelector(getFormTree);
  const selectedTree = useSelector(getSelectedTree);
  const isFinished = useSelector(getIsFinished);

  const dispatch = useDispatch();

  const classes = useStyles();

  const updateForm = (level, forms, options) => {
    if (isEmpty(options)) return [];
    for (var option of options) {
      if (option.options) {
        forms.push(
          <div className={classes.buttongroups} key={option.id}>
            <ButtonGroupSelection id={option.id} />
          </div>
        );
      }
      if (level <= selectedTree.length && option.options) {
        for (var selectionOption of option.options) {
          if (selectedTree[level] === selectionOption.id) {
            updateForm(level + 1, forms, [selectionOption]);
          }
        }
      }
    }
    return forms;
  };

  var forms = updateForm(0, [], formTree);
  return <div className={classes.form}>{forms}</div>;
};
export default MainForm;
