import makeActionCreator from "./actionCreators";
import { FormActionType } from "../constants";

export const updatePercentageSelection = makeActionCreator(
  FormActionType.CHANGE_PERCENTAGE_SELECTION,
  "selectionId",
  "index",
  "value",
  "changeType"
);
export const updateComboBoxSelection = makeActionCreator(
  FormActionType.CHANGE_COMBOBOX_SELECTION,
  "selectionId",
  "selectedItems"
);
export const updateCategorySelection = makeActionCreator(
  FormActionType.CHANGE_CATEGORY_SELECTION,
  "selectionId",
  "selectedId"
);
export const updateSimpleSelect = makeActionCreator(
  FormActionType.CHANGE_SIMPLE_SELECT,
  "selectionId",
  "value"
);
export const updateFormOptions = makeActionCreator(
  FormActionType.UPDATE_FORM_OPTIONS,
  "selectionOptions"
);

export const submitForm = makeActionCreator(
  FormActionType.SUBMIT_FORM,
  "selectedCategory"
);
export const editForm = makeActionCreator(FormActionType.EDIT_FORM);
