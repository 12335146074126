import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { updatePercentageSelection } from "../../actions/formActions";
import { PercentageActionType, DefaultSelections, SelectionType } from "../../constants";
import { getOptionById } from "../../selectors/formSelectors";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  textfield: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const PercentageSelection = ({ id }) => {
  const formOption = useSelector((state) => {
    return getOptionById(state.resultData.uncertainties, id);
  });
  
  console.log(formOption)
  
  const dispatch = useDispatch();
  const classes = useStyles();

  const materials = formOption.selection.materials;
  const percentages = formOption.selection.percentages;

  return materials.map((row, index) => (
    <div key={index}>
      <Grid container direction={"row"}>
        <FormControl className={classes.formControl}>
          <InputLabel shrink={true}>
            Material{formOption.required ? " *" : ""}
          </InputLabel>
          <Select
            value={materials[index]}
            onChange={(e) =>
              dispatch(
                updatePercentageSelection(
                  id,
                  index,
                  e.target.value,
                  PercentageActionType.MATERIAL_CHANGE
                )
              )
            }
          >
            {formOption.options.map((o) => (
              <MenuItem value={o.label} key={o.label}>
                {o.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel shrink={true}>Percentage</InputLabel>
          <TextField
              className={classes.textfield}
            onChange={(e) =>
              dispatch(
                updatePercentageSelection(
                  id,
                  index,
                  e.target.value,
                  PercentageActionType.PERCENTAGE_CHANGE
                )
              )
            }
            value={percentages[index]}
          />
        </FormControl>
      </Grid>
    </div>
  ));
};

PercentageSelection.propTypes = {
  id: PropTypes.string.isRequired,
};

export default PercentageSelection;
