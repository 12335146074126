import React, { useState } from "react";
import PropTypes from "prop-types";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { getOptionById } from "../../selectors/formSelectors";
import { updateSimpleSelect } from "../../actions/formActions";

import { DefaultSelections, SelectionType } from "../../constants";
import { merge } from "lodash";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SimpleSelect = ({ id }) => {
  let formOption = useSelector((state) => {
    return getOptionById(state.resultData.uncertainties, id);
  });
  
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <InputLabel shrink={true}>
        {formOption.label}
        {formOption.required ? " *" : ""}
      </InputLabel>
      <Select
        key={id}
        label={formOption.label}
        value={formOption.selection.value}
        onChange={(e) => {
          dispatch(updateSimpleSelect(id, e.target.value));
        }}
      >
        {" "}
        {formOption.options.map((o) => (
          <MenuItem key={o.label} value={o.label}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SimpleSelect.propTypes = {
  id: PropTypes.string.isRequired,
};
export default SimpleSelect;
