import React from "react";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { updateCategorySelection } from "../actions/formActions";

import { getIsEditForm } from "../selectors/formSelectors";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import grey from "@material-ui/core/colors/grey";

import MainForm from "./forms/MainForm";
import FormToolBar from "./forms/FormToolBar";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formWindow: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
}));

const FormWindow = (props) => {
  const isEditing = useSelector(getIsEditForm);
  const dispatch = useDispatch();

  const classes = useStyles();

  return (
    <Paper className={classes.formWindow} square={true}>
      <FormToolBar />
      <div>{isEditing ? <MainForm /> : false}</div>
    </Paper>
  );
};
export default FormWindow;
