import React from "react";
import { useSelector } from "react-redux";
import { getActiveDataPoint } from "../../selectors/dataSelectors";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { isEmpty } from "lodash";
import red from "@material-ui/core/colors/red";
import SubmitBox from "../forms/SubmitBox";
import SimpleSelect from "../forms/SimpleSelect";
import ButtonGroupSelection from "../forms/ButtonGroupSelection";
import ComboBoxSelection from "../forms/ComboBoxSelection";
import PercentageSelection from "../forms/PercentageSelection";
import { SelectionType } from "../../constants";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  references: {
    padding: "2px",
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  paperBox: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    backgroundColor: "#556cd6",
      color: 'white'
  },
}));

function InformationHeader(props) {
  let em = props.datapoint.emissions;
  return (
    <>
      <Typography variant={"h2"}>{props.datapoint.name}</Typography>
      <Typography variant={"subtitle1"} display={"inline"}>
        {em * 0.75} -{" "}
      </Typography>
      <Typography variant={"h4"} display={"inline"}>
        {em}
      </Typography>
      <Typography variant={"subtitle1"} display={"inline"}>
        {" "}
        - {em * 1.25}
      </Typography>
      <Typography variant={"h4"} display={"inline"}>
        {" "}
        g CO2e
      </Typography>
    </>
  );
}

function InformationDetailed(props) {
  const uncertainties = useSelector((state) => {
    return state.resultData.uncertainties;
  });

  let uncertainty;
  let uncertaintyForm;
  if (uncertainties && !isEmpty(uncertainties)) {
    //get most important uncertainty
    uncertainty = uncertainties[0];
    switch (uncertainty.selectionType) {
      case SelectionType.SIMPLE_SELECT:
        uncertaintyForm = <SimpleSelect id={uncertainty.label} />;
        break;
      case SelectionType.COMBOBOX_SELECTION:
        uncertaintyForm = <ComboBoxSelection id={uncertainty.label} />;
        break;
      case SelectionType.PERCENTAGE_SELECTION:
        uncertaintyForm = <PercentageSelection id={uncertainty.label} />;
        break;
      case SelectionType.CATEGORY:
        uncertaintyForm = <ButtonGroupSelection id={uncertainty.label} />;
        break;
    }
  }

  return (
    <>
      {uncertainty ? (
        <>
          {
            "Use the following parameters to make the analysis more detailed, Categories with large uncertainties are also shown in the Graph on the left."
          }
          <SubmitBox onSubmit={() => {}} onDontKnow={() => {}}>
            {uncertaintyForm}
          </SubmitBox>
        </>
      ) : (
        false
      )}
    </>
  );
}

function InformationReferences(props) {
  const classes = useStyles();
  var paperStyle = {
    backgroundColor: props.data.color,
  };
  return (
    <Paper style={paperStyle} square={true} className={classes.references}>
      <Button size="small" color="textSecondary">
        {"References"}
      </Button>
    </Paper>
  );
}

const InformationWindow = (props) => {
  const classes = useStyles();
  const selectedDataPoint = useSelector((state) => {
    return getActiveDataPoint(state.resultData.co2data);
  });
  const uncertainties = useSelector((state) => {
    return state.resultData.uncertainties;
  });

  var paperStyle = {
    backgroundColor: selectedDataPoint.color,
  };


  return (
    <div>
      <InformationHeader datapoint={selectedDataPoint} />
      <Paper square={true} className={classes.paperBox}>
        {uncertainties && !isEmpty(uncertainties) 
            ? <InformationDetailed data={selectedDataPoint} />
            : <Typography>Edit T-Shirt Options</Typography>
        }
        
      </Paper>
      <Paper style={paperStyle} square={true} className={classes.paper}>
        <p>{selectedDataPoint.details}</p>
      </Paper>
      <InformationReferences data={selectedDataPoint} />
    </div>
  );
};
export default InformationWindow;
